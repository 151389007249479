/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./linee-prodotto-selector.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../features/prodotti/feature.service";
var styles_LineeProdottoSelectorComponent = [];
var RenderType_LineeProdottoSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LineeProdottoSelectorComponent, data: {} });
export { RenderType_LineeProdottoSelectorComponent as RenderType_LineeProdottoSelectorComponent };
function View_LineeProdottoSelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i0.ɵdid(1, 147456, null, 0, i1.NgSelectOption, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), i0.ɵdid(2, 147456, null, 0, i1.ɵangular_packages_forms_forms_y, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_2); }); }
function View_LineeProdottoSelectorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "id", 0], [8, "checked", 0], [8, "value", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleToggleProduct($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("product" + _v.context.$implicit.id); _ck(_v, 1, 0, currVal_0); var currVal_1 = ("product" + _v.context.$implicit.id); var currVal_2 = (_co.selectedIds.indexOf(_v.context.$implicit.id) !== (0 - 1)); var currVal_3 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_4); }); }
function View_LineeProdottoSelectorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "mt-3 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Seleziona i prodotti e clicca su \"Aggiungi\""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LineeProdottoSelectorComponent_3)), i0.ɵdid(4, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-sm btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleAddProducts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Aggiungi"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.records; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_LineeProdottoSelectorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "select", [["class", "form-control"], ["name", ""]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "option", [["value", ""]], null, null, null, null, null)), i0.ɵdid(2, 147456, null, 0, i1.NgSelectOption, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), i0.ɵdid(3, 147456, null, 0, i1.ɵangular_packages_forms_forms_y, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(-1, null, ["Seleziona linea"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_LineeProdottoSelectorComponent_1)), i0.ɵdid(6, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LineeProdottoSelectorComponent_2)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_1 = ""; _ck(_v, 3, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.lineeProdotto$)); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.records.length; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_LineeProdottoSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-linee-prodotto-selector", [], null, null, null, View_LineeProdottoSelectorComponent_0, RenderType_LineeProdottoSelectorComponent)), i0.ɵdid(1, 49152, null, 0, i3.LineeProdottoSelectorComponent, [i4.Store, i5.FeatureService], null, null)], null, null); }
var LineeProdottoSelectorComponentNgFactory = i0.ɵccf("app-linee-prodotto-selector", i3.LineeProdottoSelectorComponent, View_LineeProdottoSelectorComponent_Host_0, { vendita: "vendita" }, { addProducts: "addProducts" }, []);
export { LineeProdottoSelectorComponentNgFactory as LineeProdottoSelectorComponentNgFactory };
