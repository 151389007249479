
    <select name="" class="form-control" (change)="handleSelect($event)">
      <option value="">Seleziona linea</option>
      <option *ngFor="let linea of lineeProdotto$ | async" [value]="linea.id">{{ linea.name }}</option>
    </select>

    <div *ngIf="records.length" class="mt-3 mb-3">
      <p>Seleziona i prodotti e clicca su "Aggiungi"</p>
      <div *ngFor="let product of records">
        <label [for]="'product' + product.id">
          <input [id]="'product' + product.id" [checked]="selectedIds.indexOf(product.id) !== -1" type="checkbox" [value]="product.id" (change)="handleToggleProduct($event, product)"> {{ product.name }}
        </label>
      </div>
      <button type="button" (click)="handleAddProducts()" class="btn btn-sm btn-secondary">Aggiungi</button>
    </div>


  