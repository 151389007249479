
    <form [formGroup]="myForm">
      <div class="form-group row" [ngClass]="{'align-items-center': !edit}">
        <label class="col-form-label">Tipologia:</label>
        <div class="control-input">
          <div *ngIf="edit">
            <select formControlName="tipologia_id" class="form-control">
              <option [value]="null">Seleziona tipologia</option>
              <option *ngFor="let tipologia of (tipologie$ | async)" [value]="tipologia.id">{{tipologia.name}}</option>
            </select>
          </div>
          <div *ngIf="!edit">
            <div>{{ appuntamento.tipologia.name }}</div>
          </div>
        </div>
      </div>

      <div class="form-group row mb-0" [ngClass]="{'mt-3': !edit}" style="flex-wrap: nowrap">
        <label class="col-form-label">Prodotti:</label>
      </div>
      <div class="mb-3">
        <app-products-selector [canEdit]="edit" (onAdd)="handleAddProduct($event)" (onRemove)="handleRemoveProduct($event)"
                               [productsList]="products$ | async" [selectedProducts]="myFormProducts.value"></app-products-selector>
      </div>

      <div class="form-group row">
        <label class="col-form-label">Note trattamento:</label>
        <div class="control-input">
          <textarea formControlName="note_trattamento" class="form-control"></textarea>
        </div>
      </div>

      <div>
        <label class="checkbox checkbox-primary">
          <input type="checkbox" formControlName="copia_scheda">
          <span></span>Copia scheda per i prossimi appuntamenti: modifiche applicate solo agli appuntamenti futuri pianificati
        </label>
      </div>

      <div *ngIf="myForm.value.copia_scheda" class="d-flex align-items-center">
        Inserisci per quanti appuntamenti vuoi copiare la scheda oltre a questa: <input style="margin-left: 10px; width: 60px" type="number"
                                                                                        class="form-control"
                                                                                        formControlName="copia_scheda_numero">
      </div>

      <div *ngIf="prevAppuntamento && prevAppuntamento.data" class="mt-3">
        <hr>
        <p><strong>Riepilogo precedente trattamento</strong></p>
        <p>
          <strong>Data:</strong>
          <ng-template ngIf="prevAppuntamento.data"> {{ prevAppuntamento.data | dateFormat: 'dd/MM/yyyy' }}</ng-template>
          <br>
          <strong>Tipologia:</strong> {{ prevAppuntamento.tipologia ? prevAppuntamento.tipologia.name : 'N/D' }}<br>
          <strong>Note:</strong> {{ prevAppuntamento.note_trattamento }}<br/>
          <strong>Prodotti:</strong> {{ prevAppuntamento.products.length ? (prevAppuntamento.products | productsNameList) : 'Nessuno' }}
        </p>
      </div>
    </form>

  